import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../../Agency/Particle';
import BannerWrapper, { DiscountLabel } from './bannerSection.style';
import Input from 'reusecore/src/elements/Input';
import NewsletterSectionWrapper, {
  NewsletterForm,
} from '../NewsletterSection/newsletterSection.style';
import UserForm from './userForm';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
  bannerData,
  backgroundImage,
  thanks,
}) => {
  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.target);

    console.log(event);
    // fetch('/api/form-submit-url', {
    //   method: 'POST',
    //   body: data,
    // });
  };

  return (
    <BannerWrapper url={backgroundImage}>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {/* <DiscountLabel>
              <Text content={Data.strapiLandingpage.banner[0].discountAmount} {...discountAmount} />
              <Text content={Data.strapiLandingpage.banner[0].discountText} {...discountText} />
            </DiscountLabel> */}
            <FeatureBlock
              title={
                <Heading
                  content={bannerData.title}
                  {...title}
                  style={{ color: bannerData.textColor }}
                />
              }
              description={
                <Text
                  content={bannerData.description}
                  {...description}
                  style={{ color: bannerData.textColor }}
                />
              }
              // button={<ButtonGroup />}
            />
            <UserForm title={bannerData.button} thanks={thanks}></UserForm>
            {/* <NewsletterForm>
              <form onSubmit={handleSubmit}>

                <Input
                  inputType="text"
                  isMaterial={false}
                  placeholder="Nome"
                  name="nome"
                  aria-label="email"
                />
                <Input
                  inputType="text"
                  isMaterial={false}
                  placeholder="Telefone"
                  name="telefone"
                  aria-label="email"
                />
                <Input
                  inputType="email"
                  isMaterial={false}
                  placeholder="Email"
                  name="email"
                  aria-label="email"
                />
                <Button type="submit" title={bannerData.button} {...btnStyle} />
              </form>

            </NewsletterForm> */}
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '70%', '60%', '50%'],
  },
  title: {
    fontSize: ['26px', '34px', '42px', '55px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
