import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { agencyTheme } from 'common/src/theme/agency';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, AgencyWrapper } from '../containers/Agency/agency.style';
import Navbar from '../containers/Agency/Navbar';
import BannerSection from '../containers/Agency/BannerSection';

import NewsletterSection from '../containers/Agency/NewsletterSection';
import FeatureSection from '../containers/Agency/FeatureSection';
import BlogSection from '../containers/Agency/BlogSection';
import TestimonialSection from '../containers/Agency/TestimonialSection';
import FaqSection from '../containers/Agency/FaqSection';

import Footer from '../containers/Agency/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';
import colors from 'common/src/theme/agency/colors';

const indexPage = data => {
  const queryResult = data.data.strapiLandingpage;
  colors.primary = queryResult.color.primaryColor;

  function renderFeature(props) {
    if (queryResult.feature) {
      return (
        <FeatureSection
          title={queryResult.featureTitle}
          description={queryResult.featureDescription}
          features={queryResult.feature}
        />
      );
    }
  }

  function renderBlog(props) {
    if (queryResult.Espaco) {
      return (
        <BlogSection
          espacos={queryResult.Espaco}
          spacesTitle={queryResult.spacesTitle}
          spacesDescription={queryResult.spacesDescription}
          spacesButton={queryResult.spacesButton}
          spacesURL={queryResult.spacesURL}
        />
      );
    }
  }

  function renderTestimonials(props) {
    if (queryResult.testimonial) {
      return (
        <TestimonialSection
          testimonial={queryResult.testimonial}
          testimonialTitle={queryResult.testimonialTitle}
          testimonialDescription={queryResult.testimonialDescription}
        />
      );
    }
  }

  function renderFaq(props) {
    if (queryResult.faq) {
      return (
        <FaqSection
          faqTitle={queryResult.faqTitle}
          faqDescription={queryResult.faqDescription}
          faq={queryResult.faq}
        />
      );
    }
  }

  function renderFooter(props) {
    if (queryResult.footer) {
      return (
        <Footer
          logoURL={queryResult.logo.publicURL}
          footerData={queryResult.footer}
        />
      );
    }
  }

  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO
          title={queryResult.seoTitle}
          description={queryResult.seoDescription}
        />
        <Modal />
        <ResetCSS />
        <GlobalStyle modalBackground={queryResult.thanks.backgroundColor} />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar logoURL={queryResult.logo.publicURL} />
            </DrawerProvider>
          </Sticky>
          <BannerSection
            bannerData={queryResult.banner}
            backgroundImage={queryResult.bannerImage.publicURL}
            thanks={queryResult.thanks}
          />
          {renderFeature()}
          {renderBlog()}
          {renderTestimonials()}
          {renderFaq()}
          {/* <AboutUsSection />
          <WorkHistory />
          <QualitySection />
          <VideoSection />
          <TeamSection />
          */}
          {/* <NewsletterSection
            title={queryResult.spacesTitle}
            buttonText={queryResult.spacesButton}
            buttonURL={queryResult.spacesURL}
          /> */}
          {renderFooter()}
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};

export default indexPage;

export const queryData = graphql`
  query queryIndexPage($id: String) {
    strapiLandingpage(id: { eq: $id }) {
      spacesTitle
      spacesDescription
      spacesButton
      spacesURL
      seoTitle
      seoDescription
      thanksTitle
      thanksDescription
      featureTitle
      featureDescription
      faqTitle
      faqDescription
      testimonialTitle
      testimonialDescription
      thanks {
        title
        description
        textColor
        backgroundColor
      }
      bannerImage {
        publicURL
      }
      logo {
        publicURL
      }
      banner {
        button
        description
        title
        textColor
      }
      footer {
        email
        phone
      }
      color {
        primaryColor
      }
      feature {
        icon
        title
      }
      faq {
        description
        id
        title
      }
      Espaco {
        nome
        local
        link
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      testimonial {
        name
        id
        designation
        comment
        avatar {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
