import React from 'react';
import Input from 'reusecore/src/elements/Input';
import Button from 'reusecore/src/elements/Button';
import { openModal, closeModal } from '@redq/reuse-modal';
import SearchPanel from '../SearchPanel';
import axios from 'axios';

import NewsletterSectionWrapper, {
  NewsletterForm,
} from '../NewsletterSection/newsletterSection.style';

export default class UserForm extends React.Component {
  state = {
    nome: '',
    telefone: '',
    email: '',
  };

  handleSubmit = event => {
    event.preventDefault();
    // alert(`Welcome ${this.state.nome} ${this.state.telefone} ${this.state.email}!`)
    axios
      .post('https://picoz-strapi.herokuapp.com/clientes', {
        nome: this.state.nome,
        email: this.state.email,
        telefone: this.state.telefone,
      })
      .then(res => {
        openModal({
          config: {
            className: 'search-modal',
            disableDragging: true,
            width: '100%',
            height: '100%',
            animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
            animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
            transition: {
              mass: 1,
              tension: 180,
              friction: 26,
            },
          },
          component: SearchPanel,
          componentProps: {
            thanks: this.props.thanks,
          },
          closeOnClickOutside: false,
        });
      });

    // document.location.href = "/reward"
  };
  render() {
    return (
      <NewsletterForm>
        <form onSubmit={this.handleSubmit}>
          <Input
            inputType="text"
            isMaterial={false}
            placeholder="Nome"
            name="nome"
            required
            aria-label="nome"
            onChange={value => {
              this.setState({
                nome: value,
              });
            }}
          />
          <Input
            inputType="text"
            isMaterial={false}
            placeholder="Telefone"
            name="telefone"
            required
            aria-label="telefone"
            onChange={value => {
              this.setState({
                telefone: value,
              });
            }}
          />
          <Input
            inputType="email"
            isMaterial={false}
            placeholder="Email"
            name="email"
            required
            aria-label="email"
            onChange={value => {
              this.setState({
                email: value,
              });
            }}
          />

          <Button type="submit" title={this.props.title} />
        </form>
      </NewsletterForm>
    );
  }
}
